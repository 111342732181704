<template>
  <div class="container-cental">
    <div style="width:100%">
      <h1 class="mt-5 mb-5">CALENDARIO DE TRABAJO</h1>
    </div>    
    <div class="container-calendar">
      <v-calendar
        class="custom-calendar"
        style="max-width: 100%"
        :masks="masks"
        :attributes="attributes"
        disable-page-swipe
        is-expanded
        ref="cal"
        @update:to-page="getDataForCalendar()"
      >
        <template v-slot:day-content="{ day, attributes }">
          <div
            class="celda-fecha"
            @click="selectedDay($event, day, attributes)"
          >
            <span
              class="day-label"
              style="
                font-size: 0.875rem;
                line-height: 1.25rem;
                color: rgba(17, 24, 39, 1);
              "
              >{{ day.day }}</span
            >
            <div style="flex-grow: 1; overflow-y: auto; overflow-x: auto">
              <p
                v-for="attr in attributes"
                :key="attr.key"
                style="
                  font-size: 0.75rem;
                  line-height: 1rem;
                  line-height: 1.25;
                  border-radius: 0.125rem;
                  padding: 0.25rem;
                  margin-bottom: 0.25rem;
                  margin-top: 0px;
                  display: flex;
                  flex-direction: column;
                "
                :class="attr.customData.class"
              >
                <span
                  >Horario:
                  {{ secondsToHM(attr.customData.data.hora_inicio) }}-{{
                    secondsToHM(attr.customData.data.hora_fin)
                  }}</span
                >
                <span style="font-size: 1.1rem"
                  >{{ attr.customData.cantidad }} Pedidos</span
                >
              </p>
            </div>
          </div>
        </template>
      </v-calendar>
      <div class="container-leyenda">
        <div
          class="container-item-leyenda"
          v-for="(color, index) in Object.keys(classDays)"
          :key="index"
        >
          <div :class="`container-color ${classDays[color]}`"></div>
          <span> dia {{ color }} </span>
        </div>
      </div>
    </div>    
    <div class="container-calendar-movil">
      <v-calendar
        is-expanded
        :attributes="attributes"
        ref="cal2"
        @update:to-page="getDataForCalendar()"
        @dayclick="clickDay($event)"
      />
      <div class="container-leyenda">
        <div
          class="container-item-leyenda"
          v-for="(color, index) in Object.keys(classDays)"
          :key="index"
        >
          <div :class="`container-color ${classDays[color]}`"></div>
          <span> dia {{ color }} </span>
        </div>
      </div>
    </div>
    <div class="container-data">
      <template v-if="diaSelected"
        ><div class="container-item-data">
          <span class="tittle-data">Fecha:</span
          ><span>{{ diaSelected.fecha | fecha }}</span>
        </div>
        <div class="container-item-data">
          <span class="tittle-data">Apertura:</span
          ><span>{{ secondsToHM(diaSelected.hora_inicio) }}</span>
        </div>
        <div class="container-item-data">
          <span class="tittle-data">Cierra:</span
          ><span>{{ secondsToHM(diaSelected.hora_fin) }}</span>
        </div>
        <div class="container-item-data">
          <span class="tittle-data"
            >Entregas cada
            {{
              diaSelected.rangos.length > 0
                ? (diaSelected.rangos[0].hora_fin +
                    1 -
                    diaSelected.rangos[0].hora_inicio) /
                  3600
                : 0
            }}
            hora:</span
          ><span>{{
            diaSelected.rangos.length > 0
              ? diaSelected.rangos[0].cantidad_limite
              : 0
          }}</span>
        </div>
        <div class="container-item-data tittle-separador">
          <span class="tittle-data">Horarios y pedidos</span>
        </div>
        <div
          v-for="(rango, index) in diaSelected.rangos"
          :key="index"
          class="container-colapsable"
        >
          <div
            class="container-item-data header-horarios"
            v-b-toggle="`${generateColapseIds(rango.pedidos)}`"
          >
            <span class="tittle-data"
              >de {{ secondsToHM(rango.hora_inicio) }} a
              {{ secondsToHM(rango.hora_fin + 1) }} (
              {{ rango.pedidos.length }} pedidos )</span
            >
          </div>
          <b-collapse
            v-for="(pedido, index2) in rango.pedidos"
            :key="index2 * 3000"
            :id="'collapse-' + pedido.id"
            class="container-item-data container-info-pedido"
          >
            <span class="tittle-data">Codigo de pedido: </span
            ><span class="mr-3">{{ pedido.codigo_pedido }}</span
            ><span class="tittle-data">Status: </span
            ><span>{{ estadoDelPedido(pedido.estado_del_pedido).text }}</span>
          </b-collapse>
        </div>
      </template>

      <template v-else>
        DIA SIN HORARIO DE TRABAJO
      </template>
    </div>
  </div>
</template>

<script>
import CalendarioService from "@/services/calendario.service.js";
import { Helpers } from "@/mixins/Helpers";
export default {
  name: "WorkCalendar",
  mixins: [Helpers],
  data() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    return {
      masks: {
        weekdays: "WWW",
      },
      attributes: [],
      calendarioService: null,
      calendarioObject: null,
      dataCalendar: [],
      classDays: {
        normal: "dia-normal",
        especial: "dia-especial",
      },
      diaSelected: null,
      windowWidth: window.innerWidth,
    };
  },
  created() {
    this.calendarioService = new CalendarioService();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });
    this.calendarioObject = this.$refs.cal;
    this.calendarioObjectMovil = this.$refs.cal2;
    this.getDataForCalendar();
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    getDataForCalendar() {
      if (this.windowWidth > 1040 && !this.calendarioObject) return false;
      if (this.windowWidth <= 1040 && !this.calendarioObjectMovil) return false;
      if (!this.calendarioObject) return false;
      const data = {
        mes:
          this.windowWidth > 1040
            ? this.calendarioObject.pages[0].month - 1
            : this.calendarioObjectMovil.pages[0].month - 1,
        year:
          this.windowWidth > 1040
            ? this.calendarioObject.pages[0].year
            : this.calendarioObjectMovil.pages[0].year,
      };

      this.calendarioService
        .diasDelCalendarioYRangos(data)
        .then((result) => {
          if (result.data.status === 200) {
            this.dataCalendar = [...result.data.data];
            this.attributes = this.dataCalendar.map((item) => {
              const dia = item.fecha.split("-")[2];
              const mes = item.fecha.split("-")[1] - 1;
              const year = item.fecha.split("-")[0];
              let cantidad = 0;
              item.rangos.forEach((rango) => {
                cantidad += rango.cantidad_solicitada;
              });
              return {
                key: item.id, //id                
                highlight:
                  this.windowWidth < 1040
                    ? item.type_day === "normal"
                      ? "blue"
                      : "red"
                    : false,
                dot:
                  cantidad > 0 && this.windowWidth < 1040
                    ? {
                        style: {
                          backgroundColor: "black",
                        },
                      }
                    : false,
                customData: {
                  title: "Aja",
                  class: this.classDays[item.type_day],
                  cantidad: cantidad,
                  data: item,
                },
                dates: new Date(year, mes, dia),
              };
            });
            console.log(this.highlights);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectedDay(event, day, atributes) {
      if (!atributes[0]) {
        this.diaSelected = null;
        return false;
      }
      this.diaSelected = { ...atributes[0].customData.data };
    },
    generateColapseIds(pedidos) {
      let cadena = "";
      pedidos.forEach((pedido) => {
        cadena += `collapse-${pedido.id} `;
      });
      console.log(cadena.trim());
      return cadena.trim();
    },
    clickDay(event) {
      if (!event.attributes[0]) {
        this.diaSelected = null;
        return false;
      }
      this.diaSelected = { ...event.attributes[0].customData.data };
    },
  },
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
}
.container-calendar {
  display: flex;
  width: 50%;
  flex-direction: column;
}
.container-calendar-movil {
  display: none;
  width: 50%;
  flex-direction: column;
}
.container-data {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 50%;
  padding: 15px 30px;
}
.container-item-data {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.tittle-data {
  font-weight: 600;
  font-size: 1.1rem;
  margin-right: 5px;
}
.tittle-separador {
  margin-top: 20px;
  background-color: #e6e6e6;
  padding: 5px;
}

.header-horarios {
  border-bottom: 1px solid #c3c3c3;
  justify-content: center;
  margin-top: 22px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  transition: 0.5s all;
}

.header-horarios:hover {
  background-color: #f0f0f0;
}

.container-info-pedido {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 1.1rem;
}

::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
.custom-calendar {
  width: 100%;
}
.container-leyenda {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.container-item-leyenda {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 15px;
}

/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
.celda-fecha {
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 10;
  overflow: hidden;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  cursor: pointer;
  transition: 0.5s all;
}
.celda-fecha:hover {
  opacity: 0.8;
}

.dia-normal {
  background: blue;
  color: white;
}
.dia-especial {
  background: red;
  color: white;
}

.back-red {
  background: red;
  color: white;
}
.back-blue {
  background: #0000ff;
  color: white;
}
.back-indigo {
  background: indigo;
  color: white;
}
.back-teal {
  background: teal;
  color: white;
}
.back-pink {
  background: pink;
  color: black;
}
.back-orange {
  background: orange;
  color: black;
}
.container-color {
  border-radius: 2px;
  height: 13px;
  width: 13px;
  border: 1px solid gray;
  margin-right: 3px;
}

@media (max-width: 1280px) {
  .container-calendar {
    width: 60%;
  }
  .container-data {
    width: 40%;
  }
}

@media (max-width: 1040px) {
  .container-calendar {
    display: none;
  }
  .container-data {
    width: 50%;
  }
  .container-calendar-movil {
    display: flex;
    justify-content: center;
    width: 50%;
  }
}

@media (max-width: 650px) {
  .container-info-pedido,
  .tittle-data {
    font-size: 0.9rem;
  }
  .container-data {
    padding: 15px 10px;
    width: 100%;
  }
  .container-calendar-movil {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>
